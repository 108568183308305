import React from "react";
import { useAuth0, withAuthenticationRequired as withAuthRequired } from '@auth0/auth0-react';

import { isBrowser } from '../utils';

const withAuthenticationRequired = isBrowser() ? withAuthRequired : (component) => component;

const LoggedInPage = () => {
  const { user: { nickname } = {} } = useAuth0();

  // todo - this should re-route you to another page
  return <h1>Congratulations, {nickname} you are logged in!</h1>;
};

export default withAuthenticationRequired(LoggedInPage);
